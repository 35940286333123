import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import aosWatch from "./animations/aosWatch.js"

class ResponsivePlayer extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className='player-wrapper'>
        <ReactPlayer
            className='react-player'
            url={this.props.url}
            width='100%'
            height='100%'
            controls={this.props.controls ? this.props.controls : true}
            playing={this.props.playing ? this.props.playing : false}
            config={{
              youtube: {
                playerVars: {
                  modestbranding: 1,
                  fs: 0,
                  cc_load_policy: 1,
                  playsinline: 0
                },
              },
            }}
        />
      </div>
    )
  }
}

export default ResponsivePlayer