import PropTypes from "prop-types"
import React from "react"

import VideoButton from "./VideoButton"
import HamburgerIconTheater from "./HamburgerIconTheater.js"
import ResponsivePlayer from "../ResponsivePlayer.js"

var classNames = require("classnames")

/* Due to various restrictions, ReactPlayer is not guaranteed to function properly on mobile devices. The YouTube player 
documentation, for example, explains that certain mobile browsers require user interaction before playing: */

const Video = props => {
  var sidebarClass = classNames({
    theater: true,
    "theater--closed": !props.isOpen,
    "theater--open": props.isOpen,
    "theater--closing": props.isClosing,
  })

  return (
    <div className={sidebarClass}>
{props.isOpen && !props.isOpening && 
      <div className="theater-content">
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column">
            
              <ResponsivePlayer
                url={props.videoUrl}
                controls={props.controls}
                playing={props.playing && !props.isClosing && !props.isOpening}
                
              />
            
          </div>
          <div className="column is-2"></div>
        </div>
      </div>
}
    </div>
  )
}
Video.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  videoUrl: PropTypes.string,
  controls: PropTypes.bool,
  playing: PropTypes.bool,
}

export default Video
